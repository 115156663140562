@font-face { font-family: Orbitron-VariableFont_wght; 
    src: url('./Orbitron-VariableFont_wght.ttf'); } 

    .webpage_header1{
        color: #000;
    
        text-shadow:  3px 3px 5px #61bf42;
        display :flex;
        flex-direction: column;
        text-align: center;
        
        font-size:80px;
        font-family:Orbitron-VariableFont_wght;
    
      }
      .descbin3{
  
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        color: #000;
        font-size: xx-large;
        text-align: center;
        border-radius: 5px;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 7%;
      }
      .row {
        display: flex;
      }
      .column2 {
        flex: 33.33%;
        padding: 2%;
        text-align:center;
      }
      .threeYear{ 
        background-color: darkgrey;     
        border: 15px solid #61bf42;
        padding: 50px;
        margin: 20px;     
        display: flex;
        flex-direction: column;
        float: left;
        width: 100%;
        padding: 50px;
        border-radius: 6px;
    
      }
      .fiveyear{
        text-align: center;
        background-color: darkgrey;
        border: 15px solid #61bf42;
        padding: 50px;
        margin: 20px;
        display: flex;
        flex-direction: column;
        float: left;
        width: 100%;
        padding: 50px;
        border-radius: 10px;
      }