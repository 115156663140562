@font-face { font-family: Orbitron-VariableFont_wght; 
    src: url('./Orbitron-VariableFont_wght.ttf'); } 

    @media only screen and (max-device-width: 380px) and (max-width: 768px){
        .webpage_header1 h1{
            font-size:large;
            
        }
        .webpage_header2 h2{
            font-size: small;
        }
        .descbin{
            display: flex;
            flex-direction: column;
            
     
          
        }
        .desc{
            height: auto;
            width: auto;
        }
        .descbin2 h3{
            font-size: large;
        }
    
        .descbin img{
            height: 50%;
            width:100%;
            
        }
        .work_done{
            display: flex;
            flex-direction: column;
        }
        .column img{
            height: 25%;
            width: 100%;
        }
        .column{
            display: flex;
            flex-direction: column;
        }
        .row{
            display: flex;
            flex-direction: column;
        }
    }    

.webpage{
    flex-direction: column;
    align-items: center;
    display:flex;
    width: 100%;
    padding-top :65px;
    margin: 0;
    
}
.webpage_header1 h1{
    font-size: 4rem;
}
.webpage_header1{
    color: #000;

    text-shadow:  3px 3px 5px #61bf42;
    display :flex;
    flex-direction: column;
    text-align: center;

    font-family:Orbitron-VariableFont_wght;

  }
  .webpage_header2 h2{
    font-size: 3rem;
  }
.webpage_header2{
    background-color: grey;
    width:100%;
    text-align: center;

}
.desc{
    align-items: center;
    margin:0;
    width: 100%;
    color: #000;
}
.descbin2 h3{
    font-size: 2rem;
}
.descbin{
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2px;
    align-items: center;
    padding:2%;
    padding-top: 0;
    color: #000;
    
  }
  
  .row {
    display: flex;
  }
  .column {
    flex: 33.33%;
    padding: 40px;
    
  }
  .descbin2{
    font-size:xx-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0;
    border-color: #000;
    border-style: outset;
    border-radius: 5px;
    background-color: #61bf42;
    width: 100%;
    color: #fff;
  }
   
.work_done{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0;
    border-color: #000;
    border-style: outset;
    border-radius: 5px;
    background-color: #61bf42;
    width:80%

}
