
h1{
  color: #61bf42;
  font-size: 65px;
  font-family: Orbitron-VariableFont_wght;
}


   h2{
    color: #61bf42;
    font-size: 65px;
    font-family: Orbitron-VariableFont_wght;
  }
  
  .container h2{
  
    color: #fff;
    font-size: 50px;
    
  
  }
  nav img{
    width:100px;
    height: 100px;
  }
  .container2 h2{
    font-size: larger;
  }
  .container2 {
    background-color: #5a5a5a;
    border-style: groove;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    width: 100vw;
    height: 300px;
    color:#fff;
    gap: 2px;

  }
  .bottomnav h1{
    font-size: 3rem;
  }
  .bottomnav h2{
    font-size: 3rem
  }
  .bottomnav{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 90px;
  font-size:x-large;
  }
  .outside {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 40px;
   text-align: center;
    width: 100%;
    gap: 75px;
  }
  .topofscreen{
    display:flex;
    flex-direction: column;
    background-color: #000;
    width: 100%;
  }
  
  .nav {
    
   
    text-align:center ;
    font-family: Orbitron-VariableFont_wght;
    font-size: 1rem;
    z-index: 1;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: stretch;
    width: 100%;
    gap: 2rem;
    padding: 0;
    padding-right: 30px;
  }
  
  
  .site-title {
    font-size: .5rem;
    padding-left: 2px;
    padding-bottom: 5px;
    text-align: left;
    text-shadow: 1px 1px 2px #DCD206;
    font-family: Orbitron-VariableFont_wght;
  }
  
  
  .nav ul {
    display:flex;
    padding: 0;
    margin: 0;
    list-style: none;
   
  
    gap: 1rem;
  
  
  
  }
  
  .nav a {
    
    color: #fff;
    text-decoration: none;
    height: 80%;
    display: flex;
    align-items: center;
    padding: .25rem;
    padding-right: 0;
    font-size: 1.5rem;
    font-family:Orbitron-VariableFont_wght;

    text-shadow: 1px 1px 2px #000;
  }
  
  .nav li:hover {
    background-color: #777;
   
  }
  .Menu{
    display: flex;
    align-items: center;
    
}
.mobileBtn{
    display: none;
    color: #fff;
}


@media only  screen and (max-width: 720px) {
    .mobileBtn {
      display: block;
      position: absolute;
      right: 1rem;
      cursor: pointer;
      z-index: 10;
    }
    

  nav {
    display: none;
  }
    .menu {
 
      display:flex;
      position: fixed;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: -10%;
      top: 0;
      bottom: 0;
      right:0 ;
      width: 100%;
      height: 100vh;
      background-color: black;
      z-index: 999;
      transition: left 1s;
    }
    .active .Menu{
      display: flex;
      left:0;
    }
  }
  @media only screen and (max-device-width: 600px) {
    .nav{
      width: 100vw;
    }
  } 
  @media only screen and (max-device-width: 450px) {
    .nav {
      padding-right: 10px;
    }
  
    .nav img {
      width: 40px;
      height: 38px;
    }
  
    .nav a {
      font-size: 20px;
   
    }
  }
  
  @media only screen and (max-device-width: 410px) {
    .nav {
      padding-right: 35px;
    }
  
    .nav a {
      font-size: 15px;
    }
  
  }
  @media only screen and (min-width: 320px){
    .bottomnav h2 {
      font-size: medium;

      
    }
    .container2{
      width:50%;
      height: 50%;
    }
    .container2 a {
      font-size: 1rem;
    }
    h2{
      color: #61bf42;
      font-size:medium;
      font-family: Orbitron-VariableFont_wght;
    }


  }
