.row{
    display: flex;
    flex-direction: row;

}
.column3 {
    display: block;
    float: left;
    width: 33.3%;
    margin-bottom: 16px;
    padding: 0 8px;
  }
  .card3 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .bios{
    
    display: flex;
    flex-direction: row;
  }
  .container3 {
    padding: 0 16px;
    font-size: x-large;

  }
  
  .container3::after, .row::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .title3 {
    color: grey;
  }
  
  .button3 {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
  }
  
  .button3:hover {
    background-color: #555;
  }