.foot {
    margin: 0;
    padding: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    vertical-align: top;
    background-color: #98989A;
    width: 100%;
    border-top: 3px outset #5884E8;
}

.eclectic {
    display: flex;
    flex-direction: row;
    width: 30%;
    align-items: center;
    text-align: left;
    padding-left: 20px;
}

.eclectic h1 {
    font-size: 30px;
}

.eclectic h2 {
    font-size: 18px;
}

.eclecticinfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    text-align: center;
    color: #29ADFF;
    font-family: 'Courier New', Courier, monospace;
}

.footdesc {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
}

.footdesc h1 {
    font-size: 15px;
    padding-bottom: 0px;
}

.footdesc h2 {
    font-size: 12px;
    padding-top: 5px;
}
@media only screen and (min-width:320px) and (max-width: 768px){
    .foot{
        width: 100vw;
        
    }
    .footdesc{
        display: flex;
        flex-direction: column;
        width: auto;
        text-align: center;
        align-items: center;
        align-content: center;
        justify-content: center;

    }
}